<template>
  <v-dialog width="800" v-model="dialog">
    <template v-slot:activator="{ on }">
      <!-- <v-hover v-slot="{ hover }"> -->
      <v-list-item v-on="on">
        <v-list-item-avatar size="30">
          <v-img :src="avatar_fornitore"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ fornitore.nome }}
          </v-list-item-title>
        </v-list-item-content>
        <!-- <v-list-item-action>
            <v-btn
              v-if="hover"
              x-small
              color="red"
              class="ml-4"
              icon
              @click="delete_arrivo()"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </v-list-item-action> -->
      </v-list-item>
      <!-- </v-hover> -->
    </template>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        <v-avatar class="mr-2"><v-img :src="avatar_fornitore" /></v-avatar>
        {{ fornitore.nome }} - Settimana # {{ settimana.numero + 1 }}
        <v-spacer></v-spacer>
        <v-btn icon @click="on_edit = !on_edit"
          ><v-icon>{{
            on_edit ? "mdi-pencil-off" : "mdi-pencil"
          }}</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-row class="mt-4" v-if="on_edit">
          <v-col>
            <v-file-input
              outlined
              v-model="file"
              accept="file/pdf"
              placeholder="Seleziona pdf"
              prepend-icon="mdi-file-pdf-box"
              label="Seleziona PDF ..."
            ></v-file-input>
          </v-col>
        </v-row>
        <v-list-item v-if="arrivo.pdf">
          <v-list-item-avatar>
            <v-icon color="red">mdi-file-pdf-box</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ arrivo.pdf }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon color="red" @click="delete_pdf()"><v-icon>mdi-delete</v-icon></v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn icon @click="print_pdf()"
              ><v-icon>mdi-arrow-down-bold</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
        <v-divider class="my-4" />
        <v-list-item
          v-for="(prodotto, index) in arrivo.prodotti"
          :key="prodotto.id"
        >
          <v-list-item-avatar
            ><v-img :src="avatar_prodotto(prodotto.id_prodotto)" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ get_prodotto(prodotto.id_prodotto)[0] }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action-text>
            <v-menu
              v-model="menu_qta_update_index[index]"
              right
              max-width="100"
              :close-on-content-click="false"
              :disabled="!on_edit"
            >
              <template v-slot:activator="{ on }">
                <span style="font-size: 18px" v-on="on">{{
                  prodotto.qta
                }}</span>
              </template>
              <v-card>
                <v-text-field
                  v-model="qta_to_update[index]"
                  outlined
                  hide-details
                  solo
                  @keypress.enter="
                    update_qta_prodotto({
                      id_prodotto: prodotto.id,
                      index_qta: index,
                    })
                  "
                ></v-text-field>
              </v-card>
            </v-menu>
          </v-list-item-action-text>
          <v-list-item-action v-if="on_edit">
            <v-btn
              icon
              x-small
              color="red"
              @click="delete_prodotto(prodotto.id)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
        <v-row class="mt-4" v-if="on_edit">
          <v-col cols="8">
            <v-autocomplete
              outlined
              prepend-inner-icon="mdi-spa"
              label="Prodotto"
              :items="prodotti"
              item-text="nome"
              item-value="id"
              v-model="id_prodotto"
              ref="prodotti_auto"
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    <v-img :src="avatar_prodotto(data.item.id)" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="qta"
              outlined
              hide-details
              :disabled="!id_prodotto"
              type="number"
              @keyup.enter="add_prodotto()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-dialog v-model="dialog_elimina" width="300" v-if="on_edit">
          <template v-slot:activator="{ on }">
            <v-btn outlined color="red" v-on="on">elimina</v-btn>
          </template>
          <v-card>
            <v-card-title primary-title class="red--text">
              Elimina
            </v-card-title>
            <v-card-text> Sei sicuro? </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn outlined color="red" @click="delete_arrivo">
                <v-icon>mdi-delete</v-icon>Si</v-btn
              >
              <v-btn text color="grey" @click="dialog_elimina = false"
                >annulla</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn text color="grey" @click="dialog = false">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import printer from "../../../service/printer";
export default {
  props: {
    arrivo: Object,
    settimana: Object,
  },
  data() {
    return {
      dialog: false,
      dialog_elimina: false,
      on_edit: false,
      menu_qta_update_index: [],
      qta_to_update: [],
      qta: 0,
      id_prodotto: null,
      file: null,
    };
  },
  watch: {
    file: function () {
      if (!this.file) {
        return;
      }
      let formData = new FormData();
      formData.append("file", this.file);
      let url =
        process.env.VUE_APP_API_ROOT +
        "/admin/calendario_arrivi/arrivo/" +
        this.arrivo.id +
        "/pdf";
      let auth = this.$store.getters.get_credential;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          auth: auth,
          onUploadProgress: (progressEvent) => {
            this.percentLoad = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then(() => {
          console.log(this.file);
          this.$store.commit("set_nome_pdf_arrivo", {
            id_settimana: this.settimana.id,
            id_arrivo: this.arrivo.id,
            file_name: this.file.name,
          });
        });
    },
  },
  computed: {
    fornitore() {
      return this.$store.getters.get_fornitore_by_id(this.arrivo.id_fornitore);
    },
    avatar_fornitore() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/fornitore/" +
        this.arrivo.id_fornitore +
        "/avatar"
      );
    },
    prodotti() {
      return this.$store.getters.get_lista_prodotti_raw;
    },
  },
  methods: {
    delete_arrivo() {
      this.$store.dispatch("rimuovi_arrivo_settimana", {
        id_arrivo: this.arrivo.id,
        id_settimana: this.settimana.id,
      });
    },
    get_prodotto(id_prodotto) {
      return this.$store.getters
        .get_prodotto_raw_by_id(id_prodotto)
        .nome.split("Vaso");
    },
    avatar_prodotto(id_prodotto) {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + id_prodotto;
    },
    print_pdf() {
      printer.print_pdf_arrivo_settimana({ id_arrivo: this.arrivo.id });
    },
    delete_pdf(){
      this.$store.dispatch("delete_pdf_arrivo_settimana", {id_settimana: this.settimana.id, id_arrivo: this.arrivo.id})
    },
    delete_prodotto(id_prodotto) {
      this.$store.dispatch("rimuovi_prodotto_arrivo", {
        id_settimana: this.settimana.id,
        id_arrivo: this.arrivo.id,
        id_prodotto: id_prodotto,
      });
    },
    update_qta_prodotto(payload) {
      console.log(payload);
      this.$store.dispatch("modifica_prodotto_arrivo", {
        id_settimana: this.settimana.id,
        id_arrivo: this.arrivo.id,
        id_prodotto: payload.id_prodotto,
        qta: this.qta_to_update[payload.index_qta],
      });
      this.menu_qta_update_index = [];
      this.qta_to_update = [];
    },
    add_prodotto() {
      this.$store
        .dispatch("aggiungi_prodotto_arrivo", {
          id_settimana: this.settimana.id,
          id_arrivo: this.arrivo.id,
          id_prodotto: this.id_prodotto,
          qta: this.qta,
        })
        .then(() => {
          this.id_prodotto = null;
          this.qta = 0;
          this.$refs.prodotti_auto.focus();
        });
    },
  },
};
</script>