<template>
  <CalendarioArrDesktop v-if="!$vuetify.breakpoint.mobile" />
  <CalendarioArrMobile v-else></CalendarioArrMobile>
</template>

<script>
import CalendarioArrDesktop from "@/components/CalendarioArr/Desktop/calendario_desktop_main.vue";
import CalendarioArrMobile from "@/components/CalendarioArr/Mobile/calendario_mobile_main.vue"
export default {
  components: {
    CalendarioArrDesktop,
    CalendarioArrMobile
  },
};
</script>