<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card v-if="settimana">
      <v-card-title class="grey--text text-subtitle-1">
        Aggiungi Arrivo {{ fornitore_lock }}
      </v-card-title>
      <v-card-subtitle>Settimana # {{ settimana.numero + 1 }}</v-card-subtitle>
      <v-card-text>
        <v-autocomplete
          :loading="loading_nuovo_arrivo"
          :disabled="fornitore_lock"
          outlined
          prepend-inner-icon="mdi-account"
          label="Fornitore"
          :items="fornitori"
          item-text="nome"
          item-value="id"
          v-model="id_fornitore"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <v-img :src="avatar_fornitore(data.item.id)" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <v-row>
          <v-col>
            <v-file-input
              :disabled="!id_fornitore"
              outlined
              v-model="file"
              accept="file/pdf"
              placeholder="Seleziona pdf"
              prepend-icon="mdi-file-pdf-box"
              label="Seleziona PDF ..."
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-autocomplete
              :disabled="!id_fornitore"
              outlined
              prepend-inner-icon="mdi-spa"
              label="Prodotto"
              :items="prodotti"
              item-text="nome"
              item-value="id"
              v-model="id_prodotto"
              ref="prodotti_auto"
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    <v-img :src="avatar_prodotto(data.item.id)" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="qta"
              outlined
              hide-details
              :disabled="!id_fornitore || !id_prodotto"
              type="number"
              @keyup.enter="add_prodotto()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="scroll">
        <v-list-item v-for="prodotto in lista_prodotti" :key="prodotto.id">
          <v-list-item-avatar
            ><v-img :src="avatar_prodotto(prodotto.id_prodotto)" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ get_prodotto(prodotto.id_prodotto)[0] }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action-text>
           <span style="font-size: 18px">{{ prodotto.qta }}</span> 
          </v-list-item-action-text>
          <v-list-item-action>
            <v-btn
              icon
              x-small
              color="red"
              @click="delete_prodotto(prodotto.id)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="chiudi()" text color="grey">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  props: {
    settimana: Object,
  },
  data() {
    return {
      file: null,
      dialog: false,
      id_fornitore: null,
      id_prodotto: null,
      id_arrivo: null,
      qta: 0,
      loading_nuovo_arrivo: false,
      fornitore_lock: false,
    };
  },
  watch: {
    id_fornitore: function () {
      if (!this.id_fornitore) {
        return;
      }
      this.loading_nuovo_arrivo = true;
      this.$store
        .dispatch("aggiungi_arrivo_settimana", {
          id_settimana: this.settimana.id,
          id_fornitore: this.id_fornitore,
        })
        .then((res) => {
          this.id_arrivo = res;
          this.loading_nuovo_arrivo = false;
          this.fornitore_lock = true;
        });
    },
    file: function () {
      let formData = new FormData();
      formData.append("file", this.file);
      let url =
        process.env.VUE_APP_API_ROOT +
        "/admin/calendario_arrivi/arrivo/" +
        this.id_arrivo +
        "/pdf";
      let auth = this.$store.getters.get_credential;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          auth: auth,
          onUploadProgress: (progressEvent) => {
            this.percentLoad = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then(() => {
          console.log(this.file);
          this.$store.commit("set_nome_pdf_arrivo", {
            id_settimana: this.settimana.id,
            id_arrivo: this.id_arrivo,
            file_name: this.file.name,
          });
        });
    },
  },
  computed: {
    fornitori() {
      return this.$store.getters.get_fornitori;
    },
    prodotti() {
      return this.$store.getters.get_lista_prodotti_raw;
    },
    lista_prodotti() {
      return this.$store.getters.get_lista_prodotti_arrivo({
        id_settimana: this.settimana.id,
        id_arrivo: this.id_arrivo,
      });
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    avatar_fornitore(id_fornitore) {
      return (
        process.env.VUE_APP_API_ROOT + "/fornitore/" + id_fornitore + "/avatar"
      );
    },
    avatar_prodotto(id_prodotto) {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + id_prodotto;
    },
    get_prodotto(id_prodotto) {
      return this.$store.getters
        .get_prodotto_raw_by_id(id_prodotto)
        .nome.split("Vaso");
    },
    add_prodotto() {
      this.$store
        .dispatch("aggiungi_prodotto_arrivo", {
          id_settimana: this.settimana.id,
          id_arrivo: this.id_arrivo,
          id_prodotto: this.id_prodotto,
          qta: this.qta,
        })
        .then(() => {
          this.id_prodotto = null;
          this.qta = 0;
          this.$refs.prodotti_auto.focus();
        });
    },
    delete_prodotto(id_prodotto) {
      this.$store.dispatch("rimuovi_prodotto_arrivo", {
        id_settimana: this.settimana.id,
        id_arrivo: this.id_arrivo,
        id_prodotto: id_prodotto,
      });
    },
    chiudi() {
      this.id_fornitore = null;
      this.fornitore_lock = false;
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.scroll {
  height: 310px;
  overflow-y: scroll;
}
</style>