<template>
  <v-card>
    <v-card-title class="grey--text text-subtitle-1"
      >Calendario Arrivi
      <v-menu v-if="!loading" bottom>
        <template v-slot:activator="{ on }">
          <span class="ml-4 green--text" v-on="on" style="cursor: pointer"
            ><b>{{ anno_rif }}</b></span
          >
        </template>
        <v-list>
          <v-list-item
            v-for="anno in anni"
            :key="anno"
            @click="set_anno_rif(anno)"
          >
            <v-list-item-content>
              <v-list-item-title> {{ anno }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <v-row v-if="loading" align="center" justify="center" class="py-8">
        <v-progress-circular indeterminate color="green" width="2" />
      </v-row>
      <v-row v-else>
        <v-col cols="3" v-for="settimana in settimane" :key="settimana.id">
          <v-card tile flat outlined class="px-2" min-height="120px">
            <!-- <v-hover v-slot="{ hover }">
              <v-card-title class="grey--text text-subtitle-1"
                >{{ settimana.numero + 1 }}
                <v-spacer />
                <v-btn
                  class="mr-2"
                  icon
                  color="green"
                  v-if="hover"
                  small
                  @click="open_aggiungi_arrivo(settimana)"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
                <v-btn
                  icon
                  color="purple"
                  v-if="hover"
                  small
                  @click="print_settimana(settimana.id)"
                  ><v-icon>mdi-printer</v-icon></v-btn
                >
              </v-card-title>
            </v-hover> -->
            <v-hover v-slot="{ hover }">
              <v-card-text>
                <v-row >
                  <v-col cols="2">
                     <span class="grey--text ml-2" style="font-size: 24px">{{ settimana.numero + 1 }}</span>
                    <v-btn
                      class=""
                      icon
                      color="green"
                      v-if="hover"
                      small
                      @click="open_aggiungi_arrivo(settimana)"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                    <v-btn
                      icon
                      color="purple"
                      v-if="hover"
                      small
                      @click="print_settimana(settimana.id)"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    >
                  </v-col>
                  <v-col cols="10">
                    <v-row
                      v-for="arrivo in settimana.arrivi"
                      align="center"
                      :key="arrivo.id"
                      no-gutters
                    >
                      <ElementoArrivo :settimana="settimana" :arrivo="arrivo" />
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-hover>
          </v-card>
        </v-col>
        <AggiungiArrivoDialog
          :settimana="settimana_sel"
          ref="aggiungi_dialog"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import AggiungiArrivoDialog from "./aggiungi_arrivo_dialog.vue";
import ElementoArrivo from "./elemento_arrivo.vue";
import printer from "../../../service/printer";
export default {
  components: {
    AggiungiArrivoDialog,
    ElementoArrivo,
  },
  data() {
    return {
      loading: true,
      settimana_sel: null,
    };
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch("fetch_calendario_arrivi", moment().format("yyyy"))
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    anni() {
      const annoCorrente = new Date().getFullYear();
      const anniPrecedenti = [annoCorrente];
      for (let i = 1; i <= 5; i++) {
        anniPrecedenti.push(annoCorrente - i);
      }
      return anniPrecedenti;
    },
    calendario() {
      return this.$store.getters.get_calendario_arrivi;
    },
    settimane() {
      return this.calendario.settimane;
    },
    anno_rif() {
      return this.calendario.anno_rif;
    },
  },
  methods: {
    set_anno_rif(anno_rif) {
      this.loading = true;
      this.$store.dispatch("fetch_calendario_arrivi", anno_rif).then(() => {
        this.loading = false;
      });
    },
    open_aggiungi_arrivo(settimana) {
      this.settimana_sel = settimana;
      console.log(this.settimana_sel);
      this.$refs.aggiungi_dialog.open();
    },

    avatar_fornitore(id_fornitore) {
      return (
        process.env.VUE_APP_API_ROOT + "/fornitore/" + id_fornitore + "/avatar"
      );
    },
    fornitore(id_fornitore) {
      return this.$store.getters.get_fornitore_by_id(id_fornitore);
    },
    print_settimana(id_settimana) {
      printer.print_pdf_settimana_calendario_arrivo({
        id_settimana: id_settimana,
      });
    },
  },
};
</script>